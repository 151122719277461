<template>
  <div class="row">
    <input-select
      v-model="filtro.tipoChecklist"
      :options="opcoes.tipoChecklist"
      class="col-6"
      :label="$t('modulos.checklist.filtro.tipo_checklist')"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.classificacaoInstrumento"
      class="col-6"
      :label="$t('modulos.checklist.filtro.classificacao_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.tipoInstrumento"
      class="col-12"
      :label="$t('modulos.checklist.filtro.tipo_instrumento')"
      em-filtro
    />
  </div>
</template>
<script>
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tipoChecklist : []
      },
    };
  },
  mounted() {
    this.buscarTipoChecklist();
  },
  methods: {
    buscarTipoChecklist : function(){
      EnumeradorService.buscar('EnumTipoChecklist').then((res) => {
        this.opcoes.tipoChecklist = res
      });
    }
  }
};
</script>

